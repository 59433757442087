import * as React from 'react';
import { Query } from 'react-apollo';
import { Button, Input, createStyles, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/PlusOne';
import { QueryRouteComponentProps } from 'shared/components/QueryRoute';
import ListViewLayout from 'shared/layout/ListViewLayout';
import { ListViewSearchArea } from 'shared/layout';
import { createStyled } from 'shared/hocs';
import * as create from 'shared/components/Table';
import { ListPageRenderProps, SortDirection } from 'shared/components/ListPage';
import { GlobalCircleLoader, ListPage } from 'shared/components';
import EnterpriseList from 'enterprise/components/EnterpriseList';
import EnterpriseCreateModal from 'enterprise/components/EnterpriseCreateModal';
import {
  EnterpriseSearchQueryResult,
  ENTERPRISE_SEARCH_QUERY,
} from 'enterprise/queries';

export interface QueryDefinition {
  search?: string;
  order: SortDirection;
  sort: string;
  perPage: number;
  page: number;
}

export interface QueryVars {
  searchText?: string;
  page: number;
  limit: number;
  order: SortDirection;
  sortBy?: string;
}

interface State {
  value: string;
  createEnterprise: boolean;
  showResults: boolean; 
}

export interface QueryRes {
  enterpriseUsers: {
    list: EnterpriseSearchQueryResult[];
    hasMore: boolean;
  };
}

type Props = QueryRouteComponentProps<QueryDefinition> &
  ListPageRenderProps<{ search?: string }>;

const colWidths = ['20%', '20%', '7%', '8%', '16%', '19%', '5%'];

class Inner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: '',
      createEnterprise: false,
      showResults: false,
    };
  }

  componentDidUpdate(pp: Props, ps: State) {
    const prev = pp.query.params.search || '';
    const { search = '' } = this.props.query.params;
    if (prev && prev !== search) {
      this.setState({ value: search });
    }
  }

  componentDidMount() {
    const { search = '' } = this.props.searchValues;
    this.setState({ value: search });
  }

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      this.search();
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.setState({ value });
  };

  search = () => {
    const { value } = this.state;

    // prevent autoloading of expensive blank Unicorn queries 
    this.setState({ showResults: true })

    const { updateSearch, clearSearch } = this.props;
    if (value) {
      updateSearch({ search: value });
    } else {
      clearSearch();
    }
  };

  openCreateEnterpriseModal = () => this.setState({ createEnterprise: true });
  closeCreateEnterpriseModal = () => this.setState({ createEnterprise: false });

  render() {
    const {
      paging,
      searchValues,
      changeRowsPerPage,
      handleChangePage,
    } = this.props;
    const { search: searchText } = searchValues;
    const { order, sortBy, page, perPage: limit } = paging;

    const headers = [
      create.header('Enterprise name', 'planName'),
      create.header('Account holder', 'username'),
      create.header('Seats', 'seatCount'),
      create.header('Status', 'status'),
      create.header('Internal contacts'),
      create.header('Organisation'),
      create.header('Actions'),
    ];

    return (
      <Style>
        {({ classes }) => (
          <ListViewLayout>
            <ListViewSearchArea>
              <Input
                fullWidth
                placeholder="Search enterprise accounts by name, account holder username etc..."
                value={this.state.value}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                disableUnderline
                className={classes.searchInput}
              />

              <Button onClick={this.search} variant="contained" color="primary">
                <SearchIcon />
              </Button>

              <Button
                style={{ marginLeft: '1%' }}
                onClick={this.openCreateEnterpriseModal}
                variant="contained"
                color="primary"
              >
                <AddIcon />
              </Button>
            </ListViewSearchArea>

            {this.state.showResults && (
              <Query<QueryRes, QueryVars>
                query={ENTERPRISE_SEARCH_QUERY}
                fetchPolicy="network-only"
                variables={{
                  page,
                  order,
                  limit,
                  sortBy,
                  searchText,
                }}
              >
                {({ data, error, loading, refetch }) => {
                  if (error && (!data || !data.enterpriseUsers))
                    return <pre>{JSON.stringify(error)}</pre>;

                  if (loading && (!data || !data.enterpriseUsers))
                    return <GlobalCircleLoader show />;

                  return (
                    <EnterpriseList
                      refetch={refetch}
                      colWidths={colWidths}
                      page={page}
                      perPage={limit}
                      headers={headers}
                      users={data!.enterpriseUsers.list}
                      hasMore={data!.enterpriseUsers.hasMore}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={changeRowsPerPage}
                    />
                  );
                }}
              </Query>
            )}

            <EnterpriseCreateModal
              onClose={this.closeCreateEnterpriseModal}
              isOpen={this.state.createEnterprise}
              cancel={this.closeCreateEnterpriseModal}
            />
          </ListViewLayout>
        )}
      </Style>
    );
  }
}

interface SearchVars {
  search?: string;
}

const sortKeys = ['created', 'username', 'planName', 'status', 'seatCount'];
const EnterpriseListPage: React.FunctionComponent<Props> = (props) => {
  const searchKeys: Array<keyof SearchVars> = ['search'];
  return (
    <ListPage<SearchVars>
      sortKeys={sortKeys}
      searchKeys={searchKeys}
      defaultSortBy="created"
    >
      {(query) => <Inner {...query} {...props} />}
    </ListPage>
  );
};

export default EnterpriseListPage;

const Style = createStyled((theme: Theme) =>
  createStyles({
    searchInput: {
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.grey[500],
      padding: theme.spacing.unit,
    },
  }),
);
